.landing1 {

  .w-input,
  .w-select {
    line-height: normal !important;
    border: 0px solid #cccccc !important;
  }

  textarea:focus, input:focus{
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .over {
    transform: rotate( -180deg );
    transition: transform 150ms ease;
  }

  .out {
    transform: rotate( -360deg );
    transition: transform 150ms ease;
  }

  .nav-link-special.form-btn {
    border-width: 0px !important;
  }

  @media screen and (max-width: 991px) {
    .input {
      font-size: 30px !important;
    }
  }

}
