.bg-home {

  background-image: url("/assets/images/ac_home_desk_bg.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  min-height: 600px;
  background-color: #6967af;

  @media only screen and (max-width: 600px) {
    background-image: url("/assets/images/app_bg_small-01.svg");
  }

  h2 {
    text-shadow: 2px 2px 4px #00000035;
  }

}

.home {

  h2 {
    font-weight: 700;
  }

  .section-2 {
    position: static;
    overflow: visible;
    background-image: radial-gradient(circle farthest-corner at 0% 100%, hsla(0, 0%, 100%, 0) 45%, #e4e4ee 82%), radial-gradient(circle farthest-corner at 0% 100%, #fbdfe8 24%, hsla(0, 0%, 100%, 0) 50%);
  }

  .section-3 {
    position: relative;
    overflow: hidden;
    margin-top: -60px;
    padding-top: 220px;
    padding-bottom: 160px;
    border-radius: 60px;
    background-color: #3a2e76;

    will-change: transform;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;

    h2 {
      color: #e8e4ff;
      text-shadow: 0 -1px 6px hsl(0deg 0% 100% / 30%);
    }

    .html-embed {
      position: absolute;
      left: 0%;
      top: 0%;
      right: 0%;
      bottom: 0%;
      width: 100%;
      height: 100%;
      -webkit-filter: blur(200px);
      filter: blur(200px);
    }

    .blog-highlights {

      background-color: #bbadff1f;

    }

  }


  .blog-header {

    .paragraph {
      max-width: 470px;
      margin-top: 44px;
      margin-bottom: 34px;
      font-family: Montserrat, sans-serif;
      color: rgba(18, 7, 71, 0.5);
      font-size: 22px;
      line-height: 26px;
    }

    h2 {
      font-family: Tiempostext,sans-serif;
      color: #120747 !important;
    }

    p {
      color: #8275a9;
    }
  }

  /* scroll */
  #marquee-left {
    -webkit-animation-name: move;
    -webkit-animation-duration: 100s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: normal;
    -webkit-animation-timing-function:linear;
    animation-name: move;
    animation-duration: 100s;
  }

  #marquee-right {
    -webkit-animation-name: move;
    -webkit-animation-duration: 100s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: reverse;
    -webkit-animation-timing-function:linear;
    animation-name: move;
    animation-duration: 100s;
  }

  @keyframes move {
    0% {
      transform:translateX(0%);
    }
    100% {
      transform:translateX(100%);
    }
  }

  @-webkit-keyframes move {
    0% {
      transform:translateX(0);
    }
    100% {
      transform:translateX(100%);
    }
  }



  /* end scroll */

  @keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  @-o-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  @-moz-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  @-webkit-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  .animate-flicker {
    -webkit-animation: flickerAnimation 1s infinite;
    -moz-animation: flickerAnimation 1s infinite;
    -o-animation: flickerAnimation 1s infinite;
    animation: flickerAnimation 1s infinite;
  }



  .monster-section-2 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 700px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-image: url('/assets/images/hero_video_planets-1.svg'), url('/assets/images/hero_video_bg-1.svg');
    background-position: 50% 50%, 50% 50%;
    background-size: contain, contain;
    background-repeat: no-repeat, no-repeat;
  }

  .monster-video-2 {
    position: relative;
    top: 130px;
    width: 30%;
    height: 120px;
    margin-right: 21px;
    background-image: url('/assets/images/hero_video_3.svg');
    background-position: 50% 50%;
    background-size: 75px 75px;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .monster-video-2:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }

  .typed-title {
    margin-top: 0px;
    font-family: Tiempostext, sans-serif;
    font-size: 82px;
    line-height: 92px;
    font-weight: 700;
  }

  .monster-container-section-3 {
      position: relative;
      display: inline-block;
      max-width: 520px;
      margin-top: 60px;
      padding: 30px 30px 30px 200px;
      border-style: solid;
      border-width: 1px;
      border-color: rgba(86, 84, 164, 0.23);
      border-radius: 22px;
      background-color: hsla(0, 0%, 100%, 0.1);
      box-shadow: 0 4px 0 0 rgba(20, 16, 41, 0.05);
  }

  .monster-section-3 {
    position: absolute;
    left: -38%;
    top: 0%;
    right: auto;
    bottom: 0%;
  }


  .typed {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .container {
    position: relative;
    width: 90%;
    max-width: 1180px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 0px;
  }

  .image-separator {
    position: absolute;
    left: 5%;
    top: auto;
    right: auto;
    bottom: -35%;
    width: 10px;
    opacity: 0.4;
  }

  .paragraph {
    max-width: 470px;
    margin-top: 44px;
    margin-bottom: 34px;
    font-family: Montserrat, sans-serif;
    color: rgba(18, 7, 71, 0.5);
    font-size: 22px;
    line-height: 26px;
  }

  .heading-3 {
    color: #120637;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: Tiempostext, sans-serif;
    font-size: 38px;
    line-height: 44px;
    font-weight: 700;
  }

  .heading-3.from_section3 {
    color: #e8e4ff;
  }

  .card_rocket {
    text-shadow: 0 -1px 6px hsla(0, 0%, 100%, 0.3);
  }

  .flair_small {
    margin-bottom: 15px;
    padding: 10px 20px;
    border-radius: 30px;
    background-color: #f1f0fd;
    color: #4a4888;
    font-size: 20px;
    font-weight: 700;
  }

  .flair_small.from_section3 {
    background-color: rgba(241, 240, 253, 0.1);
    color: #e7e4fe;
    text-shadow: none;
  }

  .div-block-33 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 25px;
  }

  .div-block-34 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-radius: 50%;
    background-color: rgba(20, 16, 41, 0.07);
    font-family: 'Fa solid 900', sans-serif;
    color: #31337c;
    font-size: 10px;
    line-height: 10px;
  }

  .text-block-41 {
    font-family: Montserrat, sans-serif;
    color: #e8e4ff;
  }

  .div-block-32 {
    display: -ms-grid;
    display: grid;
    margin-top: 40px;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .div-block-32.mt {
    margin-top: 15px;
  }

  .container-section-3 {
    text-shadow: 0 -1px 6px hsla(0, 0%, 100%, 0.3);
  }

  .big-chart-container {
    width: 241px;
    margin-top: 20px;
  }

  .price-large {
    margin-top: 24px;
    font-family: Montserrat, sans-serif;
    color: #120637;
    font-size: 48px;
    line-height: 48px;
    font-weight: 400;
  }

  .price-large.from_section3 {
    color: #e8e4ff;
  }

  .featured_buttons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .btn-cryptoverse {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 150px;
    margin-top: 30px;
    margin-right: 15px;
    padding: 16px 35px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-style: solid;
    border-width: 2px;
    border-color: rgba(18, 7, 71, 0.07);
    border-radius: 30px;
    background-color: rgba(18, 7, 71, 0.06);
    -webkit-transition: -webkit-transform 200ms cubic-bezier(.725, -.005, .25, 1);
    transition: -webkit-transform 200ms cubic-bezier(.725, -.005, .25, 1);
    transition: transform 200ms cubic-bezier(.725, -.005, .25, 1);
    transition: transform 200ms cubic-bezier(.725, -.005, .25, 1), -webkit-transform 200ms cubic-bezier(.725, -.005, .25, 1);
    font-family: Montserrat, sans-serif;
    color: #120747;
    font-size: 18px;
    text-decoration: none;

    .icon {
      color: #e8e4ff;
      font-size: 18px;
    }

    .arrow_1 {
      margin-left: 12px;
      font-family: 'Fa solid 900', sans-serif;
      font-size: 20px;
      transform: rotate(-45deg);
    }

    .arrow_2 {
      margin-left: 12px;
      -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: rotate(135deg);
      font-family: 'Fa solid 900', sans-serif;
      font-size: 20px;
    }

    .arrow_3 {
      margin-left: 12px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      font-family: 'Fa solid 900', sans-serif;
      font-size: 20px;
    }



    .text {
      color: #e5e2f4;
      font-size: 20px;
      font-weight: 600;
    }

  }

  .btn-cryptoverse:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }

  .btn-cryptoverse.nav-btn {
    -webkit-transition: -webkit-transform 200ms cubic-bezier(.667, -.005, .25, 1);
    transition: -webkit-transform 200ms cubic-bezier(.667, -.005, .25, 1);
    transition: transform 200ms cubic-bezier(.667, -.005, .25, 1);
    transition: transform 200ms cubic-bezier(.667, -.005, .25, 1), -webkit-transform 200ms cubic-bezier(.667, -.005, .25, 1);
  }

  .btn-cryptoverse.nav-btn:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }

  .btn-cryptoverse.nav-btn {
    width: 140px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .div-block-40 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 20px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    .marquee-right {
      overflow: hidden;
    }

  }

  div.simply-scroll.simply-scroll-container {
    width: 100% !important;
    height: auto !important;
  }
  div.simply-scroll-clip {
    width: 100% !important;
    height: auto !important;
  }
  div.simply-scroll-list {
    display:flex !important;
    flex:none !important;
  }
  div.div-block-40 {
    -webkit-flex-wrap: nowrap !important;
    padding-bottom: 10px !important;
    padding-top: 10px !important;
  }
  .simply-scroll {
    margin-bottom: 0px !important;
  }


  .section-how-it-works {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    padding-top: 120px;
    padding-bottom: 60px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .container_howitworks {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .container_desktop_phone {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 500px;
    max-width: 1180px;
    margin-top: 60px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    transform: translateY(100px);
    opacity: 0;
    transition: 1s all ease;
    align-content: center;
    background-image: url('/assets/images/howitworks_from_illustrator.svg');
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .container_desktop_phone.active{
    transform: translateY(0);
    opacity: 1;
  }

  .container_phone {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    width: 100%;
    height: 100%;
    transform: translateY(100px);
    opacity: 0;
    transition: 1s all ease;
    background-image: url('/assets/images/howitworks_from_illustrator-1.svg');
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .container_phone.active{
    transform: translateY(0);
    opacity: 1;
  }

  .howitworks_tabs {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 120px;
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 2px;
    border-color: rgba(18, 7, 71, 0.1);
    border-radius: 30px;
    background-color: #fff;
    box-shadow: 0 0 0 0 rgba(18, 7, 71, 0.17);
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    font-family: Montserrat, sans-serif;
    color: #120747;
    font-size: 20px;
    font-weight: 700;
  }

  .howitworks_tabs:hover {
    border-color: rgba(18, 7, 71, 0.3);
    box-shadow: 0 12px 30px 0 rgba(18, 7, 71, 0.14);
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }

  .howitworks_tabs:active {
    background-color: rgba(231, 229, 252, 0.52);
  }

  .howitworks_tabs.w--current {
    background-color: #fff;
  }

  .div-block-65 {
    width: 50px;
    height: 50px;
    background-image: url('/assets/images/icon_buy.svg');
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }


  .div-block-66 {
    width: 50px;
    height: 50px;
    background-image: url('/assets/images/icon_sell.svg');
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .div-block-67 {
    width: 50px;
    height: 50px;
    background-image: url('/assets/images/icon_swap.svg');
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .tabs-menu-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 275px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .tabs {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .tabs-content-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 800px;
    margin-top: 30px;
    padding-top: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-top: 1px solid #cec5ec;
  }

  .step {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 60px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .div-block-70 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 40%;
    max-height: 200px;
    margin-right: 15px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .div-block-68 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 60%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 0.5fr 1fr;
    grid-template-columns: 0.5fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .steps_title {
    margin-bottom: 15px;
    font-family: Montserrat, sans-serif;
    color: #4a4888;
    font-size: 20px;
    font-weight: 800;
  }

  .steps_text {
    font-family: Montserrat, sans-serif;
    color: rgba(18, 7, 71, 0.44);
    font-size: 20px;
  }

  .section-embedded-video {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    padding-top: 120px;
    padding-bottom: 120px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #3c3b65;
  }

  .container_video {
    overflow: hidden;
    border-radius: 60px;
    box-shadow: 0 50px 50px 0 rgba(0, 0, 0, 0.2);
  }

  .notification_block {
    position: absolute;
    left: -100px;
    top: 50px;
    min-width: 300px;
    padding: 20px 30px 15px;
    border-style: solid;
    border-width: 2px;
    border-color: hsla(0, 0%, 100%, 0.12);
    border-radius: 30px;
    background-color: hsla(0, 0%, 100%, 0.1);
    box-shadow: 1px 1px 50px 0 rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
  }

  .notification_title {
    margin-bottom: 10px;
    color: #fff;
    font-weight: 600;
  }

  .notification_text {
    color: #cec5ec;
    font-size: 12px;
  }

  .notification_text_container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .notification_symbol {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-radius: 50%;
    background-color: #cec5ec;
  }

  .notification_symbol_image {
    width: 8px;
    -webkit-filter: invert(100%);
    filter: invert(100%);
  }

  .notification_button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 20px;
    padding: 10px 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 30px;
    background-color: #bcbce4;
  }

  .notification_button_text {
    color: #120747;
    font-size: 12px;
    font-weight: 700;
  }

  .notification_symbol_arrow {
    color: #120747;
    font-size: 16px;
  }

  .notification_block_small {
    min-width: 300px;
    margin-bottom: 15px;
    padding: 15px 30px 15px 10px;
    border-style: solid;
    border-width: 2px;
    border-color: hsla(0, 0%, 100%, 0.12);
    border-radius: 30px;
    background-color: hsla(0, 0%, 100%, 0.1);
    box-shadow: 1px 1px 50px 0 rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
  }

  .background-video {
    height: 650px;
  }

  .notification_method {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 24px;
    height: 24px;
    margin-right: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-radius: 50%;
  }

  .notification_container_right {
    position: absolute;
    top: 50%;
    right: -100px;
  }

  .bold-text-2 {
    color: #e8e4ff;
    font-size: 18px;
  }

  .arrow_double {
    margin-left: 12px;
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
    font-family: 'Fa solid 900', sans-serif;
    font-size: 20px;
  }


  .text-block-39-copy {
    margin-left: 12px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    font-family: 'Fa solid 900', sans-serif;
    font-size: 20px;
  }

  .text-block-39-copy {
    margin-left: 12px;
    font-family: 'Fa solid 900', sans-serif;
    font-size: 20px;
  }

  .text-block-39 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: 10px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Fa solid 900', sans-serif;
    font-size: 20px;
  }

  .section-security {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    padding-top: 60px;
    padding-bottom: 120px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .container_security {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 90%;
    max-width: 1180px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 30px;
    background-color: #5754a4;
  }

  .div-block-64 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 60px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    background-color: #c2c0e6;
  }

  .div-block-64_right {
    width: 50%;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    background-color: #403e84;
    background-image: -webkit-gradient(linear, left bottom, left top, from(#444293), to(#7877a1));
    background-image: linear-gradient(360deg, #444293, #7877a1);
  }

  .bold-text-4 {
    color: #4a4888;
  }

  .flair {
    margin-bottom: 15px;
    padding: 10px 20px;
    border-radius: 30px;
    background-color: #d9d7f3;
    color: #4a4888;
    font-size: 20px;
    font-weight: 700;
  }

  .heading.security {
    color: #4a4888;
  }

  .div-block-42 {
    margin-top: 60px;
  }

  .div-block-43 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 24px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    font-family: Montserrat, sans-serif;
  }

  .div-block-43.margin-bottom {
    margin-bottom: 0px;
  }

  .div-block-44 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Fa solid 900', sans-serif;
    font-size: 22px;
    line-height: 22px;
  }

  .div-block-45 {
    position: relative;
    margin-right: 15px;
  }

  .text-block-44 {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 8px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Fa solid 900', sans-serif;
    color: #c2c0e6;
    font-size: 10px;
    line-height: 10px;
  }

  .section_security_text {
    color: rgba(18, 7, 71, 0.48);
    font-weight: 500;
  }

  .section-faq {
    overflow: hidden;
    padding-top: 120px;
    padding-bottom: 120px;
    background-image: radial-gradient(circle farthest-corner at 25% 25%, #fdf4f7 8%, hsla(0, 0%, 100%, 0) 14%), radial-gradient(circle closest-side at 50% 50%, #fdf4f7 64%, #fff);
  }

  .heading-4 {
    margin-top: 0px;
    margin-bottom: 60px;
    padding-left: 80px;
    font-family: Tiempostext, sans-serif;
    font-size: 36px;
  }

  .faq-block {
    padding: 15px 20px;
    border-style: solid;
    border-width: 1px;
    border-color: #cec5ec;
    border-radius: 24px;
  }

  .faq-block.mb {
    margin-bottom: 20px;
    -webkit-backdrop-filter: blur(11px);
    backdrop-filter: blur(11px);
  }

  .faq-question {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
  }


  .faq-ques {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
  }

  .arrow-right {
    font-family: 'Fa solid 900', sans-serif;
  }

  .faq-answer {
    overflow: hidden;
    margin-top: 0px;
  }

  .paragraph-4 {
    overflow: hidden;
    margin-right: 50px;
    margin-bottom: 0px;
    margin-left: 65px;
    color: rgba(18, 7, 71, 0.8);
  }

  .faq-wrapper {
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }

  .faq-wrapper {
    margin-bottom: 30px;
    padding: 14px 30px 18px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(86, 84, 164, 0.23);
    border-radius: 24px;
    background-color: #fff;
    box-shadow: 0 4px 0 0 rgba(20, 16, 41, 0.05);
    font-family: Montserrat, sans-serif;
  }

  .div-block-46 {
    width: 50px;
    height: 50px;
    margin-right: 14px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-radius: 50%;
    background-image: url('/assets/images/1--Fees-of-our-platform.svg');
    background-position: 0px 0px;
    background-size: auto;
  }

  .div-block-46.faq_long {
    background-image: url('/assets/images/2--Duration-time-of-each-transaction.svg');
  }

  .div-block-46.faq_offer {
    background-image: url('/assets/images/3--What-cryptocurrencies-we-offer.svg');
  }

  .div-block-46.faq_support {
    background-image: url('/assets/images/4--Customer-Support.svg');
  }

  .div-block-46.faq_safe {
    background-image: url('/assets/images/5--We-are-safe.svg');
  }

  .div-block-46.faq_learn {
    background-image: url('/assets/images/6--Learn-more-about-Crypto-world.svg');
  }

  .grid-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .text-block-45 {
    max-width: 80%;
    font-size: 18px;
    font-weight: 700;
  }

  .arrow-right {
    position: absolute;
    left: auto;
    top: auto;
    right: 0%;
    bottom: auto;
    font-family: 'Fa solid 900', sans-serif;
    font-size: 26px;
    cursor: pointer;
  }

  .faq-active {
    .arrow-right {
      -webkit-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)
    }
  }

  .w-layout-grid {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    grid-row-gap: 16px;
    grid-column-gap: 16px;
  }

  .prefooter-copy {
    position: relative;
    overflow: hidden;
    padding-top: 40px;
    padding-bottom: 400px;
  }

  .container_pre-footer {
    position: relative;
    width: 90%;
    max-width: 1180px;
    margin-right: auto;
    margin-bottom: 100px;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 0px;
  }

  .container_pre-footer.navbar-container {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .container_pre-footer.hero-container {
    max-width: 1660px;
  }

  .container_pre-footer.eliminar {
    display: none;
  }

  .container_pre-footer.from_section3 {
    text-shadow: 0 -1px 6px hsla(0, 0%, 100%, 0.3);
  }

  .prefooter-image {
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    width: 100%;
  }

  @media screen and (max-width: 767px) {

    .heading, .typed-title {
      font-size: 42px;
      line-height: 47px;
    }

    .paragraph {
      font-size: 20px;
      line-height: 24px;
    }

    .monster-section-2 {
      width: 120%;
      height: 400px;
      margin-top: 0px;
      margin-left: -35px;
      background-image: url('/assets/images/hero_video_planets-1.svg'), url('/assets/images/hero_video_bg-1.svg');
      background-position: 50% 50%, 50% 50%;
      background-size: contain, contain;
      background-repeat: no-repeat, no-repeat;
    }

    .monster-video-2 {
      position: relative;
      top: 84px;
      width: 20%;
      height: 100px;
      margin-right: 12px;
      background-image: url("/assets/images/hero_video_3.svg");
      background-position: 50% 50%;
      background-size: 50px 50px;
      background-repeat: no-repeat;
      cursor: pointer;
    }

    .section-3 {
      padding-top: 23px;
      border-radius: 30px;
    }

    .monster-container-section-3 {
      max-width: 520px;
      margin-top: 60px;
      padding: 30px 30px 30px 30px;
      border-style: solid;
      border-width: 1px;
      border-color: rgba(86, 84, 164, 0.23);
      border-radius: 22px;
      background-color: hsla(0, 0%, 100%, 0.1);
      box-shadow: 0 4px 0 0 rgba(20, 16, 41, 0.05);
    }

    .heading-second-line {
      font-size: 48px;
      line-height: 58px;
    }

    .div-block-32 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .featured_buttons {
      display: none;
    }

    .container_desktop_phone {
      margin-top: 0px;
      height: 400px;
      width: 90%;
    }

    .tabs-menu-2 {
      display: flex;
      width: 300px;
    }

    .howitworks_tabs {
      width: 90px;
      padding: 10px 15px;
      border-radius: 15px;
      font-size: 16px;
    }

    .div-block-68 {
      width: 100%;
      margin-top: 30px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .div-block-70 {
      width: 80%;
    }

    .step {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .steps_title {
      font-size: 18px;
      text-align: center;
    }

    .steps_text {
      font-size: 18px;
      text-align: center;
    }

    .tabs-content-2 {
      width: 90%;
    }

    .section-how-it-works {
      padding-bottom: 60px;
    }

    .container_security {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .div-block-64 {
      width: 100%;
      padding-right: 10%;
      padding-left: 10%;
    }

    .div-block-64_right {
      width: 100%;
    }

    .heading-4 {
      padding-left: 30px;
      font-size: 32px;
      line-height: 40px;
    }

    .section-faq {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    .section-security {
      padding-bottom: 60px;
    }

    .text-block-45 {
      margin-right: 12%;
    }

    .prefooter-image {
      width: 200%;
      max-width: 200%;
    }

    @keyframes move {
      0% {
        transform:translateX(0%);
      }
      100% {
        transform:translateX(400%);
      }
    }

    @-webkit-keyframes move {
      0% {
        transform:translateX(0);
      }
      100% {
        transform:translateX(400%);
      }
    }


  }

}


