.cryptohero1 {

  div.w-nav-overlay {
    top:0;
  }
  html {
    -webkit-tap-highlight-color: transparent;
  }


  .mainbutton {
    background-color: var(--khaki) !important;
  }

.not-active {
  pointer-events: none;
  cursor: default;
}
  .input:focus{
    outline:none;
  }
  div.simply-scroll.simply-scroll-container {
    width: 100% !important;
    height: auto !important;
  }
  div.simply-scroll-clip {
    width: 100% !important;
    height: auto !important;
  }
  div.simply-scroll-list {
    display:flex !important;
    flex:none !important;
  }
  div.div-block-40 {
    -webkit-flex-wrap: nowrap !important;
    padding-bottom: 10px !important;
    padding-top: 10px !important;
  }
  .simply-scroll {
    margin-bottom: 0px !important;
  }
  div.w-lightbox-close {
    right: 0.3vw !important;
    top: 13px !important;
  }

}
